import chroma from "chroma-js";
import { useLoaderData } from "@remix-run/react";
import { DefaultShopSlugLoaderResponse } from "~/routes/_shopSlug/types";
import { DefaultShopIdLoaderResponse } from "~/routes/shops/$shopId/types";

function getTextColor(primaryColor: string) {
  const blackContrast = chroma.contrast(primaryColor, "black");
  const whiteContrast = chroma.contrast(primaryColor, "white");

  return blackContrast > whiteContrast ? "24 24 27" : "250 250 250";
}

export default function Theme({ children }: { children: React.ReactNode }) {
  const { shopData } = useLoaderData<DefaultShopSlugLoaderResponse | DefaultShopIdLoaderResponse>();
  const primaryColor = shopData.userInterfaceSetting.primaryColor;
  const rgbPrimaryColor = chroma(primaryColor).rgb().join(" ");
  const shopTextColor = shopData.userInterfaceSetting.primaryButtonTextColor;
  const textRgbPrimaryColor = shopTextColor ? chroma(shopTextColor).rgb().join(" ") : getTextColor(primaryColor);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            :root {
              --shop-primary: ${rgbPrimaryColor};
              --shop-primary-foreground: ${textRgbPrimaryColor};
            `,
        }}
      />
      {children}
    </>
  );
}
